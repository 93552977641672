import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  AbstractAuthService,
  MainLayoutService,
  NotificationsService,
  User,
  BaseAction,
  ReferenceType,
  SelectConfiguration,
  MultiSelectConfiguration,
  PrgError,
  ObjectsUtilityService,
  UtilityService,
  UserManagementService,
} from 'prg-core-lib';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Subscription } from 'rxjs';
import {
  WorkspaceUser,
  WorkspaceUserStates,
} from './models/workspace-user.model';
import { WorkspaceUserService } from './services/workspace-user.service';
import { QualipexHeaderService } from '../layouts/services/qualipex-header.service';
import { TranslateService } from '@ngx-translate/core';
import { RoleNames } from '../enums/role-names.enum';
import { UserProfile } from './models/user-profile.model';
import { UserProfileEntitiesOperationNames } from './models/user-profile-entities-operation-names-enum';
import { UserProfileService } from './services/user-profile-service/user-profile.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-users.page',
  templateUrl: './users.page.html',
  styleUrls: ['./users.page.scss'],
})
export class UsersPage implements OnInit, OnDestroy {
  public readonly COMMUNITY_SELECT_CONFIGS: MultiSelectConfiguration =
    new MultiSelectConfiguration({
      readonly: false,
      optionLabel: 'name',
      optionValue: 'id',
      referenceType: ReferenceType.EntityType,
      referenceName: 'community',
      appendTo: 'body',
      filterPlaceHolder: this.translateService.instant('buttons.search.label'),
      emptyMessage: this.translateService.instant('primeng.emptymessage'),
      emptyFilterMessage: this.translateService.instant(
        'primeng.emptyfiltermessage'
      ),
    });
  public readonly CLIENT_SELECT_CONFIGS: SelectConfiguration =
    new SelectConfiguration({
      readonly: false,
      optionLabel: 'name',
      optionValue: 'id',
      referenceType: ReferenceType.EntityType,
      referenceName: 'client',
      appendTo: 'body',
      emptyMessage: this.translateService.instant('primeng.emptymessage'),
      emptyFilterMessage: this.translateService.instant(
        'primeng.emptyfiltermessage'
      ),
    });

  public displayNewModal: boolean = false;
  public displayFormModal: boolean = false;
  public displayRolesModal: boolean = false;
  public users: WorkspaceUser[];
  public newUserEmail: string;
  public roleName: string = RoleNames.TechnicalManager;
  public rolesList: any[];

  public clientId: string = null;

  public communityIds: string[] = null;

  public loggedUser: User = null;
  public user: User = null;
  public updatedUser: User = null;
  public selectedUserIndex: number = null;
  public updatedUserProfile: UserProfile = null;

  /**
   * auxiliar ReferenceType enum
   * @type {ReferenceType[]}
   * @public
   */
  public ReferenceType = ReferenceType;

  /**
   * auxiliar RoleNames enum
   * @type {RoleNames[]}
   * @public
   */
  public RoleNames = RoleNames;

  /**
   * Save button is disabled
   * @returns {boolean}
   */
  public roleSaveDisabled: boolean = false;

  /**
   * Save button is disabled
   * @returns {boolean}
   */
  public userSaveDisabled: boolean = false;

  /**
   * Variable to unsubscribe observables
   * @type {Subscription[]}
   * @private
   */
  private subscriptions: Subscription[] = [];

  /**
   * Property of type DynamicDialogRef to control the dialog of checklist-obs
   * @type {DynamicDialogRef}
   * @private
   */
  private dynamicDialogRef!: DynamicDialogRef;

  /**
   * New User button action key
   * @type {string}
   * @private
   */
  private readonly ACTION_KEY_NEW_USER: string = 'user-new';

  constructor(
    public dialogService: DialogService,
    private authService: AbstractAuthService,
    private translateService: TranslateService,
    private mainLayoutService: MainLayoutService,
    private qualipexHeaderService: QualipexHeaderService,
    private notificationsService: NotificationsService,
    private userManagementService: UserManagementService,
    private workspaceUserService: WorkspaceUserService,
    private userProfileService: UserProfileService,
    private utilityService: UtilityService,
    private objectsUtilityService: ObjectsUtilityService
  ) {}

  private validateEmail(email: string): boolean {
    return (
      email.match(
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      ) != null
    );
  }

  private sortUsers(): void {
    this.users = this.users.sort((one, two) => (one.name > two.name ? -1 : 1));
  }

  /**
   * ngOnInit
   */
  public ngOnInit(): void {
    this.loggedUser = this.authService.getLoggedUser();
    this.users = [];
    this.qualipexHeaderService.setDisplaySearchValue(true);
    this.workspaceUserService.getWorkspaceUserListAsync(true).then((x) => {
      this.users = x;
    });

    this.mainLayoutService.setPageTitleActions([
      new BaseAction({
        key: this.ACTION_KEY_NEW_USER,
      }),
    ]);

    this.subscriptions.push(
      this.mainLayoutService
        .getTriggeredPageTitleActionObservable()
        .subscribe((action) => {
          if (action?.key === this.ACTION_KEY_NEW_USER) {
            this.displayNewModal = true;
          }
        })
    );

    this.getApplicationRoles();
  }

  /**
   * ngOnDestroy
   */
  public ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }

  public async toggleActiveAsync(user: WorkspaceUser, event: MouseEvent) {
    event.stopPropagation();
    const newState =
      user.userWorkspaceStateId == WorkspaceUserStates.Active
        ? WorkspaceUserStates.Inactive
        : WorkspaceUserStates.Active;
    await this.workspaceUserService.stateChangeAsync(user.id, newState);
    user.userWorkspaceStateId = newState;
  }

  public async deleteUserAsync(user: WorkspaceUser, event: MouseEvent) {
    event.stopPropagation();
    if (
      await this.notificationsService.prgConfirmationService(
        'pages.workspace-users.delete-confirmation'
      )
    ) {
      await this.workspaceUserService.deleteAsync(user.id);
      var index = this.users.indexOf(user);
      if (index >= 0) {
        this.users.splice(index, 1);
      }
      this.sortUsers();
    } else {
      return;
    }
  }

  public async addNewUserAsync() {
    if (this.newUserEmail == null || this.newUserEmail.length <= 0) {
      this.notificationsService.errorNotification(
        new PrgError({
          titleKey: this.translateService.instant(
            'pages.workspace-users.errors.email-null.title'
          ),
          detailKey: this.translateService.instant(
            'pages.workspace-users.errors.email-null.message'
          ),
        })
      );
      return;
    }

    if (!this.validateEmail(this.newUserEmail)) {
      this.notificationsService.errorNotification(
        new PrgError({
          titleKey: this.translateService.instant(
            'pages.workspace-users.errors.email-invalid.title'
          ),
          detailKey: this.translateService.instant(
            'pages.workspace-users.errors.email-invalid.message'
          ),
        })
      );
      return;
    }

    if (this.roleName === RoleNames.Client && this.clientId == null) {
      this.notificationsService.errorNotification(
        new PrgError({
          titleKey: this.translateService.instant(
            'pages.workspace-users.errors.client-null.title'
          ),
          detailKey: this.translateService.instant(
            'pages.workspace-users.errors.client-null.message'
          ),
        })
      );
      return;
    }

    if (
      (this.roleName === RoleNames.CommunityManager ||
        this.roleName === RoleNames.CommunityPromotor) &&
      (this.communityIds == null || !this.communityIds.length)
    ) {
      this.notificationsService.errorNotification(
        new PrgError({
          titleKey: this.translateService.instant(
            'pages.workspace-users.errors.community-null.title'
          ),
          detailKey: this.translateService.instant(
            'pages.workspace-users.errors.community-null.message'
          ),
        })
      );
      return;
    }

    var newUsers = await this.workspaceUserService.inviteUserAsync(
      [this.newUserEmail],
      this.roleName,
      this.clientId,
      this.communityIds
    );
    if (newUsers == null || newUsers.length <= 0) {
      // TODO: PL: show an error, I don´t know how to
      return;
    }

    this.users = this.users.concat(newUsers);

    this.sortUsers();
    this.newUserEmail = '';
    this.displayNewModal = false;
  }

  public onRoleNameChanged(): void {
    this.clientId = null;
    this.communityIds = null;
  }

  public onRoleClicked(user: User, role: any, event: MouseEvent): void {
    event.stopPropagation();
    if (this.loggedUser.workspaceTokens.token.scopes.indexOf('*:*') === -1) {
      return;
    }

    this.user = user;
    this.displayRolesModal = true;
  }

  public onOutputScopesChanged(user: User): void {
    this.updatedUser = this.objectsUtilityService.clone(user);
  }

  public closeRolesDialog(): void {
    this.updatedUser = null;
    this.user = null;
  }

  public async updateUserRolesAsync(): Promise<void> {
    this.roleSaveDisabled = true;
    if (this.updatedUser != null) {
      await this.userManagementService.saveUserAsync(this.updatedUser);
    }
    this.displayRolesModal = false;
    this.roleSaveDisabled = false;
  }

  public onSelectUser(user: User, event: MouseEvent, index: any): void {
    event.stopPropagation();
    if (
      user.roles == null ||
      ((user.roles as any).indexOf(RoleNames.TechnicalManager) === -1 &&
        (user.roles as any).indexOf(RoleNames.Administrator) === -1) ||
      this.loggedUser?.workspaceTokens?.token?.scopes?.indexOf('*:*') !== -1
    ) {
      this.user = user;
      this.selectedUserIndex = index;
      this.displayFormModal = true;
    }
  }

  public onUserProfileUpdated(updatedUserProfile: UserProfile): void {
    this.updatedUserProfile = updatedUserProfile;
  }

  public async updateUserProfileAsync(): Promise<void> {
    this.userSaveDisabled = true;
    await this.utilityService.sleepMsAsync(
      environment.coreModuleConfigurations.dynamicForm.formValueChangeDebounce +
        environment.coreModuleConfigurations.dynamicForm
          .inputValueChangeDebounce
    );

    const responseUser =
      await this.userProfileService.manageUserProfileDataByEntityServiceAsync(
        'userprofile',
        UserProfileEntitiesOperationNames.Update,
        this.updatedUserProfile,
        false
      );

    if (responseUser?.entity != null) {
      this.users[this.selectedUserIndex] = new WorkspaceUser({
        ...this.users[this.selectedUserIndex],
        ...responseUser?.entity,
      });
    }

    this.displayFormModal = false;
    this.userSaveDisabled = false;
  }

  public closeProfileDialog(): void {
    this.user = null;
    this.updatedUserProfile = null;
    this.selectedUserIndex = null;
  }

  private async getApplicationRoles(): Promise<void> {
    const response = await this.workspaceUserService.getWorkspaceRoles();
    if (response != null) {
      this.rolesList = response;
    }
  }
}
