import { Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subscription, firstValueFrom } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';

import { BaseAction, MainLayoutService } from 'prg-core-lib';
import { MenuItem } from 'primeng/api';

/**
 * Title Component
 */
@Component({
  selector: 'qualipex-title',
  templateUrl: './qualipex-title.component.html',
  styleUrls: ['./qualipex-title.component.scss'],
})
export class QualipexTitleComponent implements OnInit, OnDestroy {
  @HostBinding('class.displayed') displayed: boolean = false;

  /**
   * backButtonUri
   * @type {string}
   * @public
   */
  public backButtonUri: string = null;

  /**
   * backButtonAction
   * @type {string}
   * @public
   */
  public backButtonAction: BaseAction = null;

  /**
   * title
   * @type {string}
   * @public
   */
  public title: string = '';

  /**
   * title actions
   * @type {BaseAction[]}
   * @private
   */
  public actions: BaseAction[] = [];

  /**
   * title split button items
   * @type {BaseAction[]}
   * @private
   */
  public menuItems: MenuItem[];

  /**
   * A class property used to unsubscribe observables on ngOnDestroy
   * @type {Subscription[]}
   * @private
   */
  private subscriptions: Subscription[] = [];

  /**
   * Constructor
   * @param {MainLayoutService} mainLayoutService
   * @param {TranslateService} translateService
   * @param {Router} router
   * @param {ActivatedRoute} route
   */
  constructor(
    private mainLayoutService: MainLayoutService,
    private translateService: TranslateService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    // this.subscriptions.push(
    //   this.router.events.subscribe((event: any) => {
    //     if (event instanceof NavigationEnd) {
    //       this.parseTitle();
    //     }
    //   })
    // );
  }

  /**
   * parseTitle from translation by url
   *
   * @returns {Promise<void>}
   */
  private async parseTitle(): Promise<void> {
    const urlPageTitles = await firstValueFrom(
      this.translateService.get('url-page-titles')
    );
    this.title = urlPageTitles[this.router.url] ?? '';
  }

  /**
   * ngOnInit
   *
   * @returns {Promise<void>}
   */
  public async ngOnInit(): Promise<void> {
    this.subscriptions.push(
      this.mainLayoutService
        .getPageTitleObservable()
        .subscribe(async (title) => {
          if (title != null) {
            this.title = title;
          } else {
            await this.parseTitle();
          }
          this.displayed = this.title.length > 0;
        })
    );
    this.subscriptions.push(
      this.mainLayoutService
        .getPageTitleBackButtonUriObservable()
        .subscribe((backButtonUri) => {
          this.backButtonUri = backButtonUri;
        })
    );
    this.subscriptions.push(
      this.mainLayoutService
        .getPageTitleBackButtonActionObservable()
        .subscribe((backButtonAction) => {
          this.backButtonAction = backButtonAction;
        })
    );
    this.subscriptions.push(
      this.mainLayoutService
        .getPageTitleActionsObservable()
        .subscribe((actions) => {
          this.actions = actions;
          this.menuItems = [];
          let i = 0;
          actions.forEach((action: BaseAction) => {
            i++;
            if (i === 1) {
              return;
            }
            this.menuItems.push({
              disabled:
                action.enableExpression != null &&
                !this.evalExpression(action.enableExpression),
              label: this.translateService.instant(
                'buttons.' + action.key + '.label'
              ),
              command: () => {
                this.onActionClicked(action);
              },
            });
          });
        })
    );
  }

  /**
   * this function calls the expression eval
   * @param {string} expression
   * @returns {boolean}
   */
  public evalExpression(expression: string): boolean {
    return eval(expression);
  }

  /**
   * onBackButtonClicked
   * @returns {void}
   */
  public onBackButtonClicked(): void {
    if (this.backButtonUri != null) {
      this.router.navigate([this.backButtonUri]);
    } else if (this.backButtonAction != null) {
      this.mainLayoutService.setTriggeredPageTitleAction(this.backButtonAction);
    }
  }

  /**
   * onActionClicked
   * @param {BaseAction} action
   * @returns {void}
   */
  public onActionClicked(action: BaseAction): void {
    this.mainLayoutService.setTriggeredPageTitleAction(action);
  }

  /**
   * ngOnDestroy
   *
   * @returns {void}
   */
  public ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });

    this.subscriptions = [];
  }
}
