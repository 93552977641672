import { PrgMenuItem } from 'prg-core-lib';

/**
 * Constant that defines the menu items for main menu
 */
export const SIDEBAR_MENU: PrgMenuItem[] = [
  new PrgMenuItem({
    id: 'home',
    icon: 'pi pi-home',
    routerLink: [''],
    routerLinkActiveOptions: { exact: true },
    translationBasePath: 'components.sidebar.',
    allowAnonymous: true,
  }),

  new PrgMenuItem({
    id: 'alarms',
    icon: 'pi pi-bell',
    routerLink: ['/alarms'],
    routerLinkActiveOptions: { exact: true },
    translationBasePath: 'components.sidebar.',
    permission: 'view',
    resource: 'alarm',
  }),

  new PrgMenuItem({
    id: 'communities',
    icon: 'svg communities',
    routerLink: ['/communities'],
    routerLinkActiveOptions: { exact: true },
    translationBasePath: 'components.sidebar.',
    permission: 'view',
    resource: 'community',
  }),

  new PrgMenuItem({
    id: 'clients',
    icon: 'svg clients',
    routerLink: ['/clients'],
    routerLinkActiveOptions: { exact: true },
    translationBasePath: 'components.sidebar.',
    permission: 'view',
    resource: 'client',
  }),

  new PrgMenuItem({
    id: 'diagrams',
    icon: 'svg load-diagrams',
    translationBasePath: 'components.sidebar.',
    permission: 'view',
    resource: 'loaddiagram',
    items: [
      new PrgMenuItem({
        id: 'load-diagrams',
        routerLink: ['/load-diagrams'],
        routerLinkActiveOptions: { exact: true },
        translationBasePath: 'components.sidebar.',
      }),

      new PrgMenuItem({
        id: 'production-diagrams',
        routerLink: ['/load-diagrams'],
        queryParams: { production: true },
        routerLinkActiveOptions: { exact: true },
        translationBasePath: 'components.sidebar.',
      }),
    ],
  }),

  new PrgMenuItem({
    id: 'invoices',
    icon: 'svg invoices',
    routerLink: ['/invoices'],
    routerLinkActiveOptions: { exact: true },
    translationBasePath: 'components.sidebar.',
    permission: 'view',
    resource: 'invoice',
  }),

  new PrgMenuItem({
    id: 'users',
    icon: 'pi pi-users',
    routerLink: ['/users'],
    routerLinkActiveOptions: { exact: true },
    translationBasePath: 'components.sidebar.',
    permission: 'list',
    resource: 'userprofile',
  }),

  new PrgMenuItem({
    id: 'notifications',
    icon: 'svg notifications',
    translationBasePath: 'components.sidebar.',
    permission: 'list-notification-types',
    resource: 'configurationitems',
    items: [
      new PrgMenuItem({
        id: 'notifications-list',
        icon: 'svg notifications',
        routerLink: ['/notifications'],
        routerLinkActiveOptions: { exact: true },
        translationBasePath: 'components.sidebar.',
      }),

      new PrgMenuItem({
        id: 'config-items-notification-types',
        icon: 'pi pi-cog',
        translationBasePath: 'components.sidebar.',
        routerLinkString:
          // eslint-disable-next-line @typescript-eslint/quotes, max-len
          "['/' + this.configItemsConfig.configurationItemsRoutingBasePath + '/' + this.configItemsConfig.configurationItemsRoutingNotificationsPath]",
        routerLinkActiveOptions: { exact: true },
      }),
    ],
  }),

  new PrgMenuItem({
    id: 'roles-scopes',
    icon: 'pi pi-user-edit',
    routerLinkString:
      // eslint-disable-next-line @typescript-eslint/quotes
      "['/' + this.userManagementConfig.userManagementBasePath + '/roles-scopes']",
    routerLinkActiveOptions: { exact: true },
    translationBasePath: 'components.sidebar.user-management.',
    permission: '*',
    resource: '*',
  }),

  new PrgMenuItem({
    id: 'entity-types',
    icon: 'pi pi-database',
    // eslint-disable-next-line @typescript-eslint/quotes
    routerLinkString: "['/' + this.entityTypesConfig.entityTypeBasePath]",
    routerLinkActiveOptions: { exact: true },
    translationBasePath: 'components.sidebar.',
    permission: 'list',
    resource: 'entitytype',
  }),

  new PrgMenuItem({
    id: 'state-models',
    icon: 'pi pi-file',
    // eslint-disable-next-line @typescript-eslint/quotes
    routerLinkString: "['/' + this.stateModelsConfig.stateModelsBasePath]",
    routerLinkActiveOptions: { exact: true },
    translationBasePath: 'components.sidebar.',
    permission: '*',
    resource: 'statemodel',
  }),

  new PrgMenuItem({
    id: 'lookup-table',
    icon: 'pi pi-map',
    // eslint-disable-next-line @typescript-eslint/quotes
    routerLinkString: "['/' + this.lookupTableConfig.lookupTableBasePath]",
    routerLinkActiveOptions: { exact: true },
    translationBasePath: 'components.sidebar.',
    permission: 'list',
    resource: 'lookuptable',
  }),
  new PrgMenuItem({
    id: 'queries',
    icon: 'pi pi-filter',
    // eslint-disable-next-line @typescript-eslint/quotes
    routerLinkString: "['/' + this.queriesConfig.queriesRoutingPath]",
    routerLinkActiveOptions: { exact: true },
    translationBasePath: 'components.sidebar.',
    permission: 'list',
    resource: 'query',
  }),
  new PrgMenuItem({
    id: 'dashboard',
    icon: 'pi pi-chart-bar',
    translationBasePath: 'components.sidebar.',
    permission: 'list',
    resource: 'dashboard',

    items: [
      new PrgMenuItem({
        icon: 'pi pi-plus-circle',
        id: 'create',
        routerLinkString:
          // eslint-disable-next-line @typescript-eslint/quotes
          "['/'  + this.dashboardConfig.dashboardRoutingBasePath + '/' + this.dashboardConfig.dashboardConfigRoutingPath] +'/new'",
        routerLinkActiveOptions: { exact: true },
        translationBasePath: 'components.sidebar.dashboard.',
      }),
      new PrgMenuItem({
        icon: 'pi pi-list',
        id: 'list',
        routerLinkString:
          // eslint-disable-next-line @typescript-eslint/quotes
          "['/' + this.entityTypesConfig.entityTypeBasePath + '/dashboard']",
        routerLinkActiveOptions: { exact: true },
        translationBasePath: 'components.sidebar.dashboard.',
      }),
      new PrgMenuItem({
        icon: 'pi pi-eye',
        id: 'display',
        routerLinkString:
          // eslint-disable-next-line @typescript-eslint/quotes
          "['/' + this.dashboardConfig.dashboardRoutingBasePath + '/' + this.dashboardConfig.dashboardDisplayRoutingPath]",
        routerLinkActiveOptions: { exact: true },
        translationBasePath: 'components.sidebar.dashboard.',
      }),
    ],
  }),

  new PrgMenuItem({
    id: 'config-items',
    icon: 'pi pi-cog',
    translationBasePath: 'components.sidebar.',
    routerLinkString:
      // eslint-disable-next-line @typescript-eslint/quotes, max-len
      "['/' + this.configItemsConfig.configurationItemsRoutingBasePath + '/' + this.configItemsConfig.configurationItemsRoutingAdminPath]",
    routerLinkActiveOptions: { exact: true },
    permission: 'list',
    resource: 'configurationitems',
  }),
];

/**
 * Constant that defines the menu items for user menu
 */
export const USER_MENU_HEADER: PrgMenuItem[] = [
  {
    id: 'user-menu',
    items: [
      { separator: true },
      {
        icon: 'pi pi-user',
        commandString: 'this.onClickProfile',
        id: 'my-account', //Key da tradução
        visibleString: '!!this.user',
      },
      {
        icon: 'pi pi-sign-out',
        commandString: 'this.onClickLogout',
        id: 'logout',
        visibleString: '!!this.user',
      },
      {
        icon: 'pi pi-sign-in',
        visibleString: '!this.user',
        id: 'login',
        routerLinkString:
          // eslint-disable-next-line @typescript-eslint/quotes
          "['/' + this.authConfig.authRoutingBasePath + '/' + this.authConfig.authRoutingLoginPath,]",
      },
    ],
  },
];
/**
 * Constant that defines the menu items for language menu on header
 */

export const LANGUAGE_MENU_HEADER: PrgMenuItem[] = [
  {
    id: 'language-menu',
    items: [
      { separator: true },
      {
        id: 'pt',
        commandString: '($event) => this.changeLanguage($event.item.id)',
      },
      {
        id: 'en',
        commandString: '($event) => this.changeLanguage($event.item.id)',
      },
    ],
  },
];
