import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import {
  AuthGuard,
  PrgDashboardDisplayComponent,
  PrgDashboardComponent,
  PrgConfigItemsAdminComponent,
  PrgConfigItemsUserComponent,
  PrgDisplayDashboardPage,
  PrgEntityTypesPage,
  PrgSpecificEntityTypePage,
  PrgEntityTypeElementPage,
  PrgLookupTablePage,
  PrgQueriesPage,
  PrgStateModelsPage,
  PrgStateModelPage,
  PrgUserManagementPage,
  PrgRolesScopesPage,
} from 'prg-core-lib';
import { QualipexLayoutsHeaderSidebarComponent } from './layouts/pages/qualipex-layouts-header-sidebar';

const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./home/home.module').then((m) => m.HomePageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'alarms',
    loadChildren: () =>
      import('./alarms/alarms.module').then((m) => m.AlarmsModule),
    canActivate: [AuthGuard],
    data: [{ permission: 'view', resource: 'alarm' }],
  },
  {
    path: 'communities',
    loadChildren: () =>
      import('./communities/communities.module').then(
        (m) => m.CommunitiesModule
      ),
    canActivate: [AuthGuard],
    data: [{ permission: 'view', resource: 'community' }],
  },
  {
    path: 'clients',
    loadChildren: () =>
      import('./clients/clients.module').then((m) => m.ClientsModule),
    canActivate: [AuthGuard],
    data: [{ permission: 'view', resource: 'client' }],
  },
  {
    path: 'load-diagrams',
    loadChildren: () =>
      import('./load-diagrams/load-diagrams.module').then(
        (m) => m.LoadDiagramsModule
      ),
    canActivate: [AuthGuard],
    data: [{ permission: 'view', resource: 'loaddiagram' }],
  },
  {
    path: 'notifications',
    loadChildren: () =>
      import('./notifications/notifications.module').then(
        (m) => m.NotificationsModule
      ),
    canActivate: [AuthGuard],
    data: [{ permission: 'view', resource: 'notification' }],
  },
  {
    path: 'invoices',
    loadChildren: () =>
      import('./invoices/invoices.module').then((m) => m.InvoicesModule),
    canActivate: [AuthGuard],
    data: [{ permission: 'view', resource: 'invoice' }],
  },
  {
    path: 'users',
    loadChildren: () =>
      import('./users/users.page.module').then((m) => m.UsersPageModule),
    canActivate: [AuthGuard],
    data: [{ permission: 'view', resource: 'user' }],
  },
  {
    path: 'config-items',
    loadChildren: () =>
      import('./config-items/config-items.module').then(
        (m) => m.ConfigurationItemsModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'dashboard',
    component: QualipexLayoutsHeaderSidebarComponent,
    children: [
      {
        path: 'config/:id',
        component: PrgDashboardComponent,
        canActivate: [AuthGuard],
        data: [{ permission: 'create', resource: 'dashboard' }],
      },
      {
        path: 'display/:name',
        component: PrgDashboardDisplayComponent,
        canActivate: [AuthGuard],
        data: [{ permission: 'view', resource: 'dashboard' }],
      },
      {
        path: 'display',
        component: PrgDashboardDisplayComponent,
        canActivate: [AuthGuard],
        data: [{ permission: 'view', resource: 'dashboard' }],
      },
      {
        path: 'view/:dashboardName',
        component: PrgDisplayDashboardPage,
        canActivate: [AuthGuard],
        data: [{ permission: 'view', resource: 'dashboard' }],
      },
    ],
  },
  {
    path: 'entity-types',
    component: QualipexLayoutsHeaderSidebarComponent,
    children: [
      {
        path: '',
        component: PrgEntityTypesPage,
      },
      {
        path: ':entityTypeName',
        component: PrgSpecificEntityTypePage,
        canActivate: [AuthGuard],
        data: [
          {
            permission: 'list',
            resourceUrlParameter: 'entityTypeName',
          },
        ],
      },
      {
        path: ':entityTypeName/:id',
        component: PrgEntityTypeElementPage,
        canActivate: [AuthGuard],
        data: [
          {
            permission: 'view',
            resourceUrlParameter: 'entityTypeName',
          },
        ],
      },
    ],
  },
  {
    path: 'lookup-table',
    component: QualipexLayoutsHeaderSidebarComponent,
    children: [
      {
        path: '',
        component: PrgLookupTablePage,
      },
    ],
  },
  {
    path: 'queries',
    component: QualipexLayoutsHeaderSidebarComponent,
    children: [
      {
        path: '',
        component: PrgQueriesPage,
      },
    ],
  },
  {
    path: 'state-models',
    component: QualipexLayoutsHeaderSidebarComponent,
    children: [
      {
        path: '',
        component: PrgStateModelsPage,
      },
      {
        path: ':stateModelId',
        component: PrgStateModelPage,
      },
    ],
  },
  {
    path: 'user-management',
    loadChildren: () =>
      import('./user-management/user-management.module').then(
        (m) => m.UserManagementModule
      ),
  },
  {
    path: 'terms-of-use',
    loadChildren: () =>
      import('./terms/terms.module').then((m) => m.TermsModule),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      paramsInheritanceStrategy: 'always',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
