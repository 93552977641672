import { Component, OnInit } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import {
  AbstractAuthService,
  AbstractEntityTypeService,
  Filter,
  FilterExpressions,
  FilterGroup,
  FilterOperations,
  MainLayoutService,
  PrgConfigHeader,
  PrgConfigSidebarMenu,
  PrgMenuItem,
  AbstractTokenService,
  User,
} from 'prg-core-lib';
import { PrimeNGConfig } from 'primeng/api';
import {
  LANGUAGE_MENU_HEADER,
  SIDEBAR_MENU,
  USER_MENU_HEADER,
} from './menus-items-layout/menus-structure-layout';
import { QualipexHeaderService } from './layouts/services/qualipex-header.service';
import { RoleNames } from './enums/role-names.enum';

@Component({
  selector: 'app-root',
  /*templateUrl: './app.component.html',*/
  template: `
    <ion-app>
      <router-outlet></router-outlet>
    </ion-app>
  `,
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  private langChangeFromSelf: boolean = false;
  constructor(
    private authService: AbstractAuthService,
    private entityTypeService: AbstractEntityTypeService,
    private mainLayoutService: MainLayoutService,
    private qualipexHeaderService: QualipexHeaderService,
    private primeNGConfig: PrimeNGConfig,
    private router: Router,
    private tokenService: AbstractTokenService,
    private translateService: TranslateService
  ) {
    this.mainLayoutService.setHeaderConfig(
      new PrgConfigHeader({
        menuItemsUser: USER_MENU_HEADER,
        menuItemsLanguage: LANGUAGE_MENU_HEADER,
      })
    );
  }

  ngOnInit(): void {
    this.setupTranslateService();
    const config = this.router.config;
    config.push({
      path: '**',
      redirectTo: '',
      pathMatch: 'full',
    });
    this.router.resetConfig(config);

    this.authService.getLoggedUserObservable().subscribe(async (user) => {
      await this.setupSideBarMenuItems(user);
    });

    this.router.events.subscribe(async (event: any) => {
      const activeWorkspaceIndex = event?.url?.indexOf('/active-workspace/');
      if (event instanceof NavigationStart && activeWorkspaceIndex !== -1) {
        const workspaceId = event.url.substring(
          activeWorkspaceIndex + '/active-workspace/'.length,
          event.url.length
        );
        if (workspaceId != null && workspaceId.length) {
          await this.tokenService.getWorkspaceTokenByIdAsync(workspaceId);
        }
      }
      if (event instanceof NavigationEnd) {
        this.qualipexHeaderService.setDisplaySearchValue(false);
        this.mainLayoutService.setPageTitleActions([]);
        this.mainLayoutService.setPageTitleBackButtonUri(null);
        this.mainLayoutService.setPageTitleBackButtonAction(null);
        this.mainLayoutService.setPageTitle(null);
      }
    });
  }

  private async setupSideBarMenuItems(user: User): Promise<void> {
    if (user == null) {
      return;
    }

    let menuItems = SIDEBAR_MENU;

    const communityWorkspaces = Array.isArray(
      user.workspaceTokens.token.workspaces
    )
      ? user.workspaceTokens.token.workspaces.filter((w) => w !== 'default')
      : [];
    if (
      communityWorkspaces.length === 1 &&
      ((user.workspaceTokens.token.role as any) ===
        RoleNames.CommunityManager ||
        user.workspaceTokens.token.role.indexOf(RoleNames.CommunityManager) !==
          -1)
    ) {
      const filterGroup = new FilterGroup();
      filterGroup.filterCollections = [
        new Filter({
          filterExpression: FilterExpressions.And,
          filterOperation: FilterOperations.EqualTo,
          propertyName: 'WorkspaceId',
          value: communityWorkspaces[0],
        }),
      ];
      const communityResponse =
        await this.entityTypeService.getEntityTypeElementsMinimalList(
          'community',
          null,
          null,
          filterGroup
        );
      if (
        communityResponse != null &&
        communityResponse.entity != null &&
        communityResponse.entity.length
      ) {
        menuItems[menuItems.findIndex((m) => m.id === 'communities')] =
          new PrgMenuItem({
            id: communityResponse.entity[0].name,
            icon: 'svg communities',
            routerLink: ['/communities/' + communityResponse.entity[0].id],
            routerLinkActiveOptions: { exact: true },
            translationBasePath: null,
            permission: 'view',
            resource: 'community',
          });
      }
    }

    this.mainLayoutService.setSidebarConfig(
      new PrgConfigSidebarMenu({
        mainMenuItems: menuItems,
      })
    );
  }

  private setupTranslateService(): void {
    /// Configure translation service
    /// {
    // TODO: Get languages and default language from configuration

    this.translateService.langs = ['pt', 'en'];
    let currentLanguage = this.translateService.langs.includes(
      navigator.language
    )
      ? navigator.language
      : this.translateService.langs[0];

    let userLanguageSaved = JSON.parse(
      localStorage.getItem('qualipex-language-default')
    );
    if (userLanguageSaved != null) {
      currentLanguage = userLanguageSaved;
    }

    this.setUpLanguage(currentLanguage);
  }

  private setUpLanguage(currentLanguage: string): void {
    this.translateService.setDefaultLang(currentLanguage);
    this.langChangeFromSelf = true;
    this.translateService.use(currentLanguage);
    this.translateService.onLangChange.subscribe((event: any) => {
      localStorage.removeItem('qualipex-language-default');
      localStorage.setItem(
        'qualipex-language-default',
        JSON.stringify(event?.lang)
      );
      if (this.langChangeFromSelf) {
        this.translateService.get('primeng').subscribe((res) => {
          this.primeNGConfig.setTranslation(res);
        });
        this.langChangeFromSelf = false;
      } else {
        window.location.reload();
      }
    });
  }
}
